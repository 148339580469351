header.header, footer.footer {
  display: none;
}

@media print {
  @page {
    margin: 0 50px;
  }
}

.recipe .recipe-header-image-container {
  display: none;
}

.recipe .row:first-child .col-md-4 {
  flex: none;
  width: 100%;
}

.recipe .row > .mt-5 {
  margin-top: 1rem !important;
}

.recipe ol {
  margin-bottom: .25rem !important;
}

.recipe .recipe-step-tip.my-3 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.recipe .ingredient-group.mb-3 {
  margin-bottom: 0 !important;
}

.recipe .ingredient-group.mb-3 .mb-4, .recipe .ingredient-group.mb-3 .table {
  margin: .25rem !important;
}

.recipe .preface {
  font-size: 16px;
}

.recipe .ingredient-group h4, .recipe .recipe-step-group h3 {
  font-size: 13px;
}

.recipe .recipe-step-group, .recipe .recipe-step-tip {
  font-size: 12px;
}

.recipe .col-md-4 {
  flex: none;
  width: 40%;
}

.recipe > :last-child {
  display: none;
}

.recipe table tbody {
  grid-template-columns: auto;
  display: grid;
}

.recipe table tbody tr {
  display: contents;
}

.recipe table tbody tr td {
  border-bottom: 1px solid var(--color-ink-border-subtle) !important;
}
/*# sourceMappingURL=index.894a5924.css.map */
